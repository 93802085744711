import styled from 'styled-components/macro';

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  line-height: 40px;

  @media (max-width: 575.98px) {
    padding: 0 32px;
  }

  @media (max-width: 767.98px) {
    max-width: 400px;
    text-align: center;
  }
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 28px;
  margin-top: 8px;

  @media (max-width: 575.98px) {
    row-gap: 20px;
  }
`;

export const StyledCardShowContractContainer = styled.div`
  max-width: 400px;
  margin: auto;

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

export const StyledCheckboxContainer = styled.div`
  width: 100%;
  border: ${({ theme }) => theme.color.borderGray} 1px solid;
  border-radius: 8px;

  display: flex;
  align-items: center;

  padding: 8px;
`;

export const StyledSubTitle = styled.div`
  font-family: ${({ theme }) => theme.font.bold}, sans-serif;
  color: ${({ theme }) => theme.color.gray2};
  font-size: 16px;
  line-height: 1.4;
  text-align: left;
  margin-top: 10px;

  @media (max-width: 575.98px) {
    font-size: 12px;
    line-height: 1.3;
  }
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.color.gray2};
  font-size: 16px;
  line-height: 1.4;
  text-align: left;

  @media (max-width: 575.98px) {
    font-size: 12px;
    width: 256px;
    line-height: 1.3;
  }
`;

export const StyledCheckboxText = styled(StyledText)`
  color: ${({ theme }) => theme.color.black1};

  margin-left: 8px;
`;

export const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
