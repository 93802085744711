import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  position: relative;
  margin-bottom: 15px;
  text-align: left;
`;

export const StyledLabel = styled.label`
  font-family: ${({ theme }) => theme.font.semiBold};
  color: ${({ theme }) => theme.color.gray2};
  font-size: 16px;
`;

export const StyledInput = styled.input`
  height: 48px;
  width: 100%;
  max-width: 400px;
  min-width: 256px;

  color: ${({ theme }) => theme.color.gray2};
  background-color: ${({ theme }) => theme.color.gray3};
  font-size: 16px;
  border-radius: 8px;
  border: none;
  outline: none;
  padding: 0 24px;
`;
