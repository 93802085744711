import BlueGenericButton from 'components/blue-generic-button/BlueGenericButton';
import { StyledBackButton } from 'components/generic-signIn/styles';
import { isEmpty } from 'lodash';
import SignatureMethods from 'model/enums/signature-methods';
import { CSSProperties, ReactNode } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { IoMdArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowIconSvg } from '../../assets/images/audit-sign/blue-arrow-left.svg';
import {
  StyledButtonInnerContainer,
  StyledButtonsContainer,
  StyledContainer,
  StyledIconContainer,
  StyledStep,
  StyledStepsContainer,
  StyledSubtitle,
  StyledTitle,
  StyledTitleContainer,
} from './styles';

interface IProps extends WithTranslation {
  i18nKeyTitle: string;
  i18nKeySubtitle?: string;
  highlightedText?: string;
  interpolatedValue?: number;
  textAlign?: string;
  style?: CSSProperties;
  className?: string;
  showBackButton?: boolean;
  loadingBackButton?: boolean;
  disableMainButton?: boolean;
  mainButtonLoading?: boolean;
  i18nKeyMainButtonText?: string;
  i18nKeyButtonText?: string;
  iconButton?: ReactNode;
  handleClickMainButton?: () => void;
  handleClickButton?: () => void;
  signatureMethods?: SignatureMethods[];
  currentSignatureMethod?: SignatureMethods;
  redirect?: () => void;
}

const PageTitleSection = ({
  i18nKeyTitle,
  signatureMethods,
  currentSignatureMethod,
  i18nKeySubtitle,
  highlightedText,
  interpolatedValue,
  textAlign,
  style,
  className,
  showBackButton,
  disableMainButton,
  mainButtonLoading,
  i18nKeyMainButtonText,
  i18nKeyButtonText,
  iconButton,
  handleClickMainButton,
  handleClickButton,
  redirect,
  loadingBackButton,
  t,
}: IProps) => {
  const navigate = useNavigate();

  return (
    <StyledContainer textAlign={textAlign}>
      {signatureMethods != null && currentSignatureMethod != null && (
        <StyledStep onClick={() => navigate(-1)}>
          <StyledIconContainer>
            <ArrowIconSvg />
          </StyledIconContainer>

          <StyledStepsContainer>
            {t('global.field.amountSteps', {
              currentStep: signatureMethods.indexOf(currentSignatureMethod) + 1,
              numberSteps: signatureMethods.length,
            })}
          </StyledStepsContainer>
        </StyledStep>
      )}
      {!loadingBackButton && showBackButton && (
        <StyledBackButton onClick={() => (redirect ? redirect() : navigate(-1))}>
          <IoMdArrowBack />
          {t('global.button.backStep')}
        </StyledBackButton>
      )}
      <StyledTitleContainer style={style}>
        <StyledTitle textAlign={textAlign} className={className}>
          {t(i18nKeyTitle)}
        </StyledTitle>

        <StyledButtonsContainer>
          {i18nKeyButtonText != null && !isEmpty(i18nKeyButtonText) && handleClickButton != null && (
            <BlueGenericButton onClick={() => handleClickButton()} invertButtonColor style={{ width: '175px' }}>
              <StyledButtonInnerContainer>
                {iconButton}
                {t(i18nKeyButtonText)}
              </StyledButtonInnerContainer>
            </BlueGenericButton>
          )}
          {i18nKeyMainButtonText != null && handleClickMainButton != null && (
            <BlueGenericButton
              disabled={disableMainButton}
              isLoading={mainButtonLoading}
              onClick={() => handleClickMainButton()}
              style={{ width: '180px' }}
            >
              {t(i18nKeyMainButtonText)}
            </BlueGenericButton>
          )}
        </StyledButtonsContainer>
      </StyledTitleContainer>
      {i18nKeySubtitle != null && (
        <StyledSubtitle textAlign={textAlign} style={style}>
          <Trans i18nKey={i18nKeySubtitle} className="subtitle">
            {highlightedText !== null && <span className="highlighted">{{ textHighlighted: highlightedText }}</span>}
            {interpolatedValue !== null && <span>{{ interpolatedValue: interpolatedValue }}</span>}
          </Trans>
        </StyledSubtitle>
      )}
    </StyledContainer>
  );
};

export default withTranslation()(PageTitleSection);
