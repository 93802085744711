import ReactLoading from 'react-loading';
import { ScreenLoading } from './styled';

interface ILoadingScreenProps {
  isLoading?: boolean;
  children?: JSX.Element | JSX.Element[] | any;
  width?: string;
  minWidth?: string;
  height?: string;
  minHeight?: string;
  color?: string;
}

const LoadingScreen: React.FC<ILoadingScreenProps> = ({ isLoading, children, width, minWidth, height, minHeight, color }) => {
  return isLoading ? (
    <ScreenLoading customStyle={{ width: width, minWidth: minWidth, height: height, minHeight: minHeight }}>
      <ReactLoading type="spinningBubbles" color={color ?? '#fff'} height={24} width={24} />
    </ScreenLoading>
  ) : (
    <>{children}</>
  );
};

export default LoadingScreen;
