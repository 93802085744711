export enum SignatureStatus {
  SIGNED = 'SIGNED',
  PENDING = 'PENDING',
}

export enum SignatureType {
  PART = 'PART',
  WITNESS = 'WITNESS',
  CONTRACTOR = 'CONTRACTOR',
  HIRED = 'HIRED',
  ADMINISTRATOR = 'ADMINISTRATOR',
  SIGN_TO_ACKNOWLEDGE_RECEIPT = 'SIGN_TO_ACKNOWLEDGE_RECEIPT',
  SIGN_TO_APPROVE = 'SIGN_TO_APPROVE',
  SIGN_TO_RATIFY = 'SIGN_TO_RATIFY',
  GUARANTOR = 'GUARANTOR',
  TRANSFEROR = 'TRANSFEROR',
  TRANSFEREE = 'TRANSFEREE',
  JOINT_DEBTOR = 'JOINT_DEBTOR',
  ISSUER = 'ISSUER',
  ENDORSER = 'ENDORSER',
  ENDORSEE = 'ENDORSEE',
  WARRANTOR = 'WARRANTOR',
  MANAGER = 'MANAGER',
  INTERVENING_PARTY = 'INTERVENING_PARTY',
  LESSOR = 'LESSOR',
  LESSEE = 'LESSEE',
  PURCHASER = 'PURCHASER',
  SELLER = 'SELLER',
  ATTORNEY = 'ATTORNEY',
  LEGAL_REPRESENTATIVE = 'LEGAL_REPRESENTATIVE',
  JOINTLY_LIABLE = 'JOINTLY_LIABLE',
  VALIDATOR = 'VALIDATOR',
}
