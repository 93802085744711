import styled from 'styled-components/macro';
const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${({ theme }) => theme.color.gray};
  font-size: 1.5rem;
  margin-top: 20%;
`;

export default StyledLoadingContainer;
