import { InputTypes } from 'model/enums/input-types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { InputProps } from '..';
import { StyledError, StyledIconBox, StyledInput, StyledInputBox, StyledLabel } from '../styles';

const Input: React.FC<InputProps> = ({ label, type, placeholder, name, formData, setFormData, mask, maxLength }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const {
    register,
    formState: { errors },
    setError,
    reset,
    setValue,
  } = useFormContext();

  const handleChange = (e, fieldName) => {
    if (mask) {
      setFormData?.({ ...formData, [fieldName]: mask(e.target.value) });
      setValue(fieldName, mask(e.target.value));
    } else {
      setFormData?.({ ...formData, [fieldName]: e.target.value });
      setValue(fieldName, e.target.value);
    }
  };

  return (
    <>
      <StyledLabel>{label}</StyledLabel>
      <StyledInputBox hasError={Object.keys(errors).includes(name)}>
        <StyledInput
          {...register(name)}
          name={name}
          placeholder={placeholder}
          onChange={e => handleChange(e, name)}
          hasError={Object.keys(errors).includes(name)}
          value={formData?.[name] ?? ''}
          maxLength={maxLength}
          type={showPassword ? InputTypes.TEXT : type}
          autoComplete={'off'}
        />
        {type === InputTypes.PASSWORD && (
          <StyledIconBox onClick={() => setShowPassword(!showPassword)}>
            {!showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
          </StyledIconBox>
        )}
        <StyledError>{errors[name]?.message}</StyledError>
      </StyledInputBox>
    </>
  );
};

export default Input;
