import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISubscriptionData } from 'model/contract-details';

interface IState {
  subscriptionData?: ISubscriptionData;
}

const initialState: IState = {
  subscriptionData: undefined,
};

const dataSigningContractSlice = createSlice({
  name: '@@dataSigningContract',
  initialState,
  reducers: {
    addSignatureData(state, action: PayloadAction<ISubscriptionData>) {
      state.subscriptionData = { ...state.subscriptionData, ...action.payload };
    },
  },
});

export const { addSignatureData } = dataSigningContractSlice.actions;

export default dataSigningContractSlice.reducer;
