import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../config/i18n';

interface UseRequestErrorReturn {
  openAlert: boolean;
  errorMessage: string;
  makeRequestWithErrorHandler: (makeRequestFunction: () => Promise<void>) => Promise<void>;
}

export const useRequestErrorHandler = (): UseRequestErrorReturn => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { t } = useTranslation();

  const catchFunction = async (error: any) => {
    if (error.status) {
      if (i18n.exists(`signFlow.statusError.${error.status}`)) {
        setErrorMessage(t(`signFlow.statusError.${error.status}`));
      } else {
        setErrorMessage(t('signFlow.statusError.default'));
      }
    }

    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 2000);
  };

  const makeRequestWithErrorHandler = async (makeRequestFunction: () => Promise<void>) => {
    try {
      await makeRequestFunction();
    } catch (error: any) {
      await catchFunction(error);
    }
  };

  return {
    openAlert,
    errorMessage,
    makeRequestWithErrorHandler,
  };
};

export default useRequestErrorHandler;
