import styled from 'styled-components/macro';

interface IStyledProps {
  status: string;
}

export const StyledContainer = styled.span<IStyledProps>`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${({ theme, status }) => `${theme.color[status.toLowerCase()]}`};
`;
