import ButtonArrow from 'components/button-arrow/ButtonArrow';
import ModalViewingContract from 'components/modal-viewing-contract/ModalViewingContract';
import { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/storeConfig';
import { ReactComponent as FrameSvg } from '../../assets/images/audit-sign/frame-large.svg';
import { StyledContainer, StyledTitle } from './styles';
import ModalViewingMultipleContracts from 'components/modal-viewing-multiple-contracts/ModalViewingMultipleContracts';

const CardShowContract = ({ t }: WithTranslation) => {
  const { contract } = useSelector((state: RootState) => state);

  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowModalContracts, setIsShowModalContracts] = useState<boolean>(false);

  const handleOpenModal = () => {
    if (contract.contracDetails?.document != null) {
      setIsShowModal(true);
    } else {
      setIsShowModalContracts(true);
    }
  };

  return (
    <StyledContainer>
      <div>
        <StyledTitle>{t('letsSign.contract')}</StyledTitle>
        <div>{t('letsSign.readContractBeforeSigning')}</div>
        <ButtonArrow text={t('global.button.openDocument')} onClick={() => handleOpenModal()} white />
      </div>

      <div>
        <FrameSvg style={{ width: '100%' }} />
      </div>

      <ModalViewingMultipleContracts
        isOpen={isShowModalContracts}
        onCloseModal={() => setIsShowModalContracts(false)}
        contracts={contract.contracDetails?.envelope?.documents}
      />

      <ModalViewingContract
        isOpen={isShowModal}
        onCloseModal={() => setIsShowModal(false)}
        documentBase64={contract.contracDetails?.documentBase64}
        contentType={contract.contracDetails?.document?.originalDocument?.contentType}
      />
    </StyledContainer>
  );
};

export default withTranslation()(CardShowContract);
