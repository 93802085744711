import { lighten } from 'polished';
import styled from 'styled-components/macro';

interface IStyledProps {
  lineUpCorners: boolean;
}

interface IStyledNavigationProps {
  isEnabled?: boolean;
}

export const StyledContainer = styled.div`
  overflow-y: auto;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 50px;

  background-color: ${({ theme }) => theme.color.grayBackground};
`;

export const StyledHeader = styled.header`
  background-color: ${({ theme }) => lighten(0.03, theme.color.colorPrimary)};
  height: auto;
  padding: 12px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledInnerContainer = styled.div<IStyledProps>`
  width: 100%;
  height: 100%;
  max-width: 992px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: ${({ lineUpCorners }) => (lineUpCorners ? 'space-between' : 'center')};

  @media (max-width: 767.98px) {
    padding: 0 32px;
  }
`;

export const StyledUserLoggedContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin-left: 4px;

  padding: 6px 8px;

  border: 1px solid #4589ff50;
  color: #ffffff;

  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  cursor: pointer;
`;

export const StyledNavigation = styled.nav`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const StyledLink = styled.a<IStyledNavigationProps>`
  width: 162px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme, isEnabled }) => (isEnabled ? lighten(0.1, theme.color.colorPrimary) : theme.color.colorPrimary)};
  border-radius: 8px;
  font-weight: ${({ isEnabled }) => (isEnabled ? 700 : 400)};
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${({ isEnabled }) => (isEnabled ? '#fff' : '#d0e2ff')};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ isEnabled }) => (isEnabled ? '#fff' : '#d0e2ff')};
  }

  &:focus-visible {
    outline: none;
  }
`;

export const StyledFooter = styled.footer`
  width: 100%;

  text-align: center;
  padding-bottom: 20px;
  color: ${({ theme }) => theme.color.gray50};
  font-size: 12px;

  @media (max-width: 767.98px) {
    padding-bottom: 12px;
  }
`;

export const StyledImage = styled.img`
  max-width: 120px;
  object-fit: contain;
`;
