import { AUTHORITIES } from 'config/constants';
import jwt_decode from 'jwt-decode';
import LocalStorageKeys from 'model/enums/local-storage-keys';
import { User } from 'model/user';

class AuthUtils {
  static AUTH_TOKEN_KEY = 'jhi-authenticationToken';

  static isAuthenticated = () => {
    return localStorage.getItem(AuthUtils.AUTH_TOKEN_KEY) !== null;
  };

  static isUser = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    return jwt_decode(AuthUtils.getToken()!).auth.includes(AUTHORITIES.USER);
  };

  static isAdmin = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    return jwt_decode(AuthUtils.getToken()!).auth.includes(AUTHORITIES.ADMIN);
  };

  static getToken = () => {
    return localStorage.getItem(AuthUtils.AUTH_TOKEN_KEY);
  };

  static setToken = (token: string) => {
    localStorage.setItem(AuthUtils.AUTH_TOKEN_KEY, token);
  };

  static removeToken = () => {
    localStorage.removeItem(AuthUtils.AUTH_TOKEN_KEY);
  };
  static setAccount = (user: User) => {
    localStorage.setItem(LocalStorageKeys.ACCOUNT_KEY, JSON.stringify(user));
  };

  static getAccount = (): User | undefined => {
    const account = localStorage.getItem(LocalStorageKeys.ACCOUNT_KEY);
    return account ? JSON.parse(account) : undefined;
  };
  static removeAccount = () => {
    localStorage.removeItem(LocalStorageKeys.ACCOUNT_KEY);
  };

  static getMainPath = () => {
    return AuthUtils.isAdmin() ? '/admin/my-files/documents' : AuthUtils.isUser() ? '/home' : '/';
  };
}

export default AuthUtils;
