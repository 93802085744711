import { yupResolver } from '@hookform/resolvers/yup';
import BlueGenericButton from 'components/blue-generic-button/BlueGenericButton';
import CardShowContract from 'components/card-show-contract/CardShowContract';
import CustomInputText from 'components/custom-input-text/CustomInputText';
import PageTitleSection from 'components/page-title-section/PageTitleSection';
import useHelperNavigation, { routeNextStepSignature } from 'hooks/useHelperNavigation';
import { ISubscriptionData } from 'model/contract-details';
import SignatureMethods from 'model/enums/signature-methods';
import TypeMasks from 'model/enums/type-masks';
import { FormProvider, useForm } from 'react-hook-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/storeConfig';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { addSignatureData } from '../../redux/slice/data-signing-contract';
import { StyledCardShowContractContainer, StyledContainer, StyledInnerContainer } from './styles';
import SubscriptionInformationCard from 'components/subscription-information-card/SubscriptionInformationCard';
import { formatDateLocal } from 'shared/util/date-utils';

interface IPersonalData {
  name: string;
  cpf: string;
  birth: string;
}

const ConfirmDetails = ({ t }: WithTranslation) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contract, location, signature } = useSelector((state: RootState) => state);
  const { isLoadingSendingContractSignature } = useHelperNavigation();

  const gelocation: GeolocationCoordinates = JSON.parse(location.geolocationData ?? '');

  const schema = yup.object({
    name: yup.string().required(),
    cpf: yup
      .string()
      .test('validate-cpf', t('global.errorMessage.invalidIndividualRegistration'), value =>
        StringUtils.validateIndividualRegistration(value)
      ),
    birth: yup.string().test('validate-birth', t('global.errorMessage.invalidDate'), value => StringUtils.checkIfIsValidDate(value)),
  });

  const methods = useForm<IPersonalData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const handleFormSubmit = (data: IPersonalData) => {
    const nextStep = routeNextStepSignature(SignatureMethods.DATA_CONFIRMATION, contract.contracDetails?.signatureMethods);

    const subscriptionData: ISubscriptionData = {
      person: {
        id: contract.contracDetails?.person?.id,
        cpf: data.cpf.replace(/\D/g, ''),
      },
      personSignature: {
        lat: gelocation.latitude,
        lng: gelocation.longitude,
        ip: location.internetProtocol ?? '',
        name: data.name,
        birth: data.birth,
      },
    };

    dispatch(addSignatureData(subscriptionData));
    navigate(nextStep);
  };

  return (
    <StyledContainer>
      <PageTitleSection
        signatureMethods={contract.contracDetails?.signatureMethods}
        currentSignatureMethod={SignatureMethods.DATA_CONFIRMATION}
        i18nKeyTitle="confirmDetails.title"
        textAlign="left"
      />

      <StyledInnerContainer>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <CustomInputText
              name="name"
              label={t('confirmDetails.fullName')}
              defaultValue={signature.subscriptionData?.personSignature?.name ?? contract.contracDetails?.name}
              errorMessage={methods.formState.errors.name?.message}
            />
            <CustomInputText
              name="cpf"
              label={t('confirmDetails.individualRegistration')}
              defaultValue={signature.subscriptionData?.person?.cpf ?? contract.contracDetails?.person?.cpf}
              mask={TypeMasks.CPF}
              errorMessage={methods.formState.errors.cpf?.message}
            />
            <CustomInputText
              name="birth"
              label={t('confirmDetails.birthDate')}
              defaultValue={formatDateLocal(contract.contracDetails?.birth)}
              mask={TypeMasks.DATE}
              errorMessage={methods.formState.errors.birth?.message}
            />

            <BlueGenericButton style={{ marginTop: '25px' }} isLoading={isLoadingSendingContractSignature}>
              {t('global.button.confirm')}
            </BlueGenericButton>
          </form>
        </FormProvider>

        <StyledCardShowContractContainer>
          {contract.contracDetails?.batchSignatureData != null ? <SubscriptionInformationCard /> : <CardShowContract />}
        </StyledCardShowContractContainer>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default withTranslation()(ConfirmDetails);
