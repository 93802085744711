import { rtkApi } from 'api/rtkApi';
import { ICreatePassword } from 'model/contract-details';

interface ForgotPassword {
  cpf: string;
  email?: string;
}

export const userApi = rtkApi.injectEndpoints({
  endpoints: builder => ({
    forgotPassword: builder.mutation<ForgotPassword, ForgotPassword>({
      query: payload => ({
        body: payload,
        method: 'POST',
        url: '/account/reset-password/init/cpf',
      }),
    }),
    createPassword: builder.mutation<ICreatePassword, ICreatePassword>({
      query: payload => ({
        body: payload,
        method: 'POST',
        url: '/account/user-creation/finish',
      }),
    }),
    resetPassword: builder.mutation<ICreatePassword, ICreatePassword>({
      query: payload => ({
        body: payload,
        method: 'POST',
        url: '/account/reset-password/finish',
      }),
    }),
  }),
});

export const { useForgotPasswordMutation, useCreatePasswordMutation, useResetPasswordMutation } = userApi;
