import styled from 'styled-components/macro';
export const StyledIconContainer = styled.div`
  text-align: right;
  margin: 16px;
  cursor: pointer;
`;

export const StyledContractContainer = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0 auto 96px auto;

  overflow-y: auto;
  overflow-x: hidden;

  .react-pdf__Document {
    height: 100%;
    width: 100%;
    max-width: 960px;
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    max-width: 960px;

    margin-bottom: 15px;
  }

  @media (max-width: 1000.98px) {
    padding: 0 1.8vh;
  }
`;

export const StyledFooter = styled.div`
  height: 96px;
  width: 100%;

  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5%;

  background-color: #d0e2ff;
`;
