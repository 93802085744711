import axios, { AxiosResponse } from 'axios';
import INetworkData from 'model/network-data';
import { api } from './api';
import { LocationResponse } from 'model/person-singature-location';

const networkDataApi = () => {
  const getInternalApiInternetProtocol = (): Promise<AxiosResponse<string>> => {
    return api.get<string>('/people/ip');
  };

  const getPublicApiInternetProtocol = (): Promise<AxiosResponse<INetworkData>> => {
    return api.get<INetworkData>('https://geolocation-db.com/json/');
  };

  const getPublicApiLocalizationByIP = (ipAddress: string): Promise<AxiosResponse<LocationResponse>> => {
    return axios.get<LocationResponse>(`http://ip-api.com/json/${ipAddress}`);
  };

  return {
    getInternalApiInternetProtocol,
    getPublicApiInternetProtocol,
    getPublicApiLocalizationByIP,
  };
};

export default networkDataApi();
