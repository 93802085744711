import styled from 'styled-components/macro';
interface IStyledProps {
  textAlign?: string;
}

export const StyledContainer = styled.div<IStyledProps>`
  max-width: ${({ textAlign }) => (textAlign != null ? '390px' : '100%')};
  position: relative;
`;

export const StyledStep = styled.div`
  width: 135px;

  position: absolute;
  top: -35px;
  left: 2px;

  font-family: ${({ theme }) => theme.font.bold}, sans-serif;
  color: ${({ theme }) => theme.color.gray2};
  font-size: 14px;

  @media (max-width: 575.98px) {
    left: 33px;
  }
`;

export const StyledIconContainer = styled.div`
  position: absolute;

  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.color.gray2};
  }

  @media (max-width: 575.98px) {
    left: -32px;
  }
`;

export const StyledStepsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const StyledTitle = styled.div<IStyledProps>`
  font-family: ${({ theme }) => theme.font.bold}, sans-serif;
  color: ${({ theme }) => theme.color.gray2};
  font-size: 31px;
  text-align: left;
  margin-bottom: 16px;

  @media (max-width: 575.98px) {
    font-size: 20px;
    text-align: ${({ textAlign }) => textAlign ?? 'center'};
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const StyledButtonInnerContainer = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 8px;
`;

export const StyledSubtitle = styled.div<IStyledProps>`
  color: ${({ theme }) => theme.color.gray2};
  font-size: 16px;
  line-height: 1.4;
  text-align: left;

  @media (max-width: 575.98px) {
    font-size: 12px;
    text-align: ${({ textAlign }) => textAlign ?? 'center'};
    width: 256px;
    margin: ${({ textAlign }) => (textAlign != null ? 'none' : '0 auto')};
    line-height: 1.3;
  }

  .highlighted {
    font-family: ${({ theme }) => theme.font.semiBold}, sans-serif;
  }
`;
