import { isValid, parse } from 'date-fns';
import { isEmpty, isNil } from 'lodash';

class StringUtils {
  static isStringEmpty = (event?: string) => {
    return isNil(event) || event === '';
  };

  static isPasswordInvalid = (event: string) => {
    return StringUtils.isStringEmpty(event) || event.length < 5 || event.length > 250;
  };

  static isSyntaxEmpty = (event?: string) => {
    return event === '${}' || event === '#{}';
  };
  static getFirstName = (fullName: string) => {
    if (fullName === '') return '';

    return fullName.split(' ')?.[0];
  };

  static isSizeInvalid = (size: number, event?: string) => {
    if (StringUtils.isStringEmpty(event)) return true;
    return event?.length !== size;
  };
  static cpfMask(documentNumber?: string) {
    if (documentNumber == null) {
      return '';
    }
    documentNumber = documentNumber.replace(/\D/g, '');

    return documentNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
  static generateFileUrl = (file: string, type: string) => {
    const blobObject = StringUtils.b64toBlob(file, type, null);
    return URL.createObjectURL(blobObject);
  };

  static b64toBlob = (b64Data, contentType, size) => {
    const sliceSize = size ?? 512;

    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType ?? '' });
  };

  static isEmailInvalid = (event?: string) => {
    if (StringUtils.isStringEmpty(event)) return true;
    const emailRegex = /\S+@\S+\.\S+/;
    return !emailRegex.test(event!);
  };

  static toObject = (key: string, event: any) => {
    const result = {};
    result[key] = event;
    return result;
  };

  static randomColor = () => {
    const colors: string[] = ['#14b071', '#4f35a5', '#1e98d6'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  static randomString = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };
  static removeMask = (value: string) => {
    return value.replace(/[^\d]/g, '');
  };
  static b64EncodeUnicode = (str: string) => {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
        return String.fromCharCode(Number(`0x${p1}`));
      })
    );
  };

  static removeAccentToLowerCase = (event?: string) => {
    if (StringUtils.isStringEmpty(event)) return '';

    let withoutAccent;

    withoutAccent = event!.replace(new RegExp('[Ç]', 'gi'), 'c');
    withoutAccent = withoutAccent.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    withoutAccent = withoutAccent.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    withoutAccent = withoutAccent.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    withoutAccent = withoutAccent.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    withoutAccent = withoutAccent.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');

    return withoutAccent.toLowerCase();
  };

  static validateIndividualRegistration = (value?: string) => {
    value = value?.replace(/[^\d]+/g, '');
    let sum = 0;
    let rest = 0;

    if (isEmpty(value)) {
      return false;
    }

    if (
      value === '00000000000' ||
      value === '11111111111' ||
      value === '22222222222' ||
      value === '33333333333' ||
      value === '44444444444' ||
      value === '55555555555' ||
      value === '66666666666' ||
      value === '77777777777' ||
      value === '88888888888' ||
      value === '99999999999'
    ) {
      return false;
    }

    for (let i = 1; i <= 9; i++) sum = sum + Number(value?.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== Number(value?.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum = sum + Number(value?.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== Number(value?.substring(10, 11))) return false;
    return true;
  };

  static checkIfIsValidDate = (value?: string) => {
    if (value == null) {
      return false;
    }

    const dateConvertedValidFormat = parse(value, 'dd/MM/yyyy', new Date());
    return isValid(dateConvertedValidFormat);
  };

  static cellMask = (phone?: string) => {
    if (phone == null) {
      return '';
    }

    phone = phone.replace(/\D/g, '');
    return phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/g, '+$1 ($2) $3-$4');
  };

  static maskAmount = (value?: number | string) => {
    if (value != null) {
      return value.toLocaleString('pt-BR', { minimumFractionDigits: 0 });
    }

    return '';
  };
}

export default StringUtils;

export function validateCpf(value: string) {
  if (value == null || value === '') {
    return false;
  }
  const cpf = value.replace(/[^\d]+/g, '');
  let add = 0;
  let rev = 0;
  if (cpf === '') {
    return false;
  }
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }
  add = 0;
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf.charAt(9), 10)) {
    return false;
  }
  add = 0;
  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf.charAt(10), 10)) {
    return false;
  }
  return true;
}
