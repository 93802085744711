import TypeMasks from 'model/enums/type-masks';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ErrorMessage from '../error-message/ErrorMessage';
import { StyledContainer, StyledInput, StyledLabel } from './styles';

interface IProps {
  name: string;
  label: string;
  defaultValue?: string;
  mask?: TypeMasks;
  errorMessage?: string;
}

const applyMask = (value: string, typeMask: TypeMasks) => {
  if (typeMask === TypeMasks.DATE) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1');
  }

  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

const CustomInputText = ({ name, label, defaultValue, mask, errorMessage }: IProps) => {
  const { register } = useFormContext();

  const [value, setValue] = useState(defaultValue);

  useMemo(() => {
    if (mask != null && value != null) {
      setValue(applyMask(value, mask));
    }
  }, []);

  return (
    <StyledContainer>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <StyledInput
        {...register(name)}
        id={name}
        maxLength={255}
        value={value}
        onChange={e => setValue(mask != null ? applyMask(e.target.value, mask) : e.target.value)}
      />
      {errorMessage != null && <ErrorMessage text={errorMessage} style={{ position: 'absolute' }} />}
    </StyledContainer>
  );
};

export default CustomInputText;
