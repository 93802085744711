import moment from 'moment';

import { APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATETIME_FORMAT_Z, APP_LOCAL_DATE_FORMAT } from 'config/constants';
import { format, isValid, parse, parseISO } from 'date-fns';
import { isEmpty } from 'lodash';

export const convertDateTimeFromServer = (date?: Date | null) => {
  if (date != null) {
    date = new Date(date);

    if (isValid(date)) {
      return format(new Date(date), APP_LOCAL_DATETIME_FORMAT);
    }

    return '';
  }

  return '';
};

export const convertDateTimeToServer = (
  date: string | number | void | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | undefined
) => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export const formatDateLocal = (date?: string): string => {
  const iso8601FormatString: RegExp = /\d{4}-\d{2}-\d{2}/;
  const parsedDate =
    date != null && !isEmpty(date)
      ? iso8601FormatString.test(date)
        ? parseISO(date)
        : parse(date, APP_LOCAL_DATE_FORMAT, new Date())
      : null;

  return parsedDate ? format(parsedDate, APP_LOCAL_DATE_FORMAT) : '';
};
