import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  geolocationData?: string;
  internetProtocol?: string;
}

const initialState: IState = {
  geolocationData: undefined,
  internetProtocol: undefined,
};

const locationDataSlice = createSlice({
  name: '@@locationData',
  initialState,
  reducers: {
    addGeolocationCoordinates(state, action: PayloadAction<string>) {
      state.geolocationData = action.payload;
    },
    addInternetProtocol(state, action: PayloadAction<string>) {
      state.internetProtocol = action.payload;
    },
  },
});

export const { addGeolocationCoordinates, addInternetProtocol } = locationDataSlice.actions;

export default locationDataSlice.reducer;
