import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ReactComponent as ChevronLeftIconSvg } from '../../assets/images/audit-sign/chevron-left.svg';
import { ReactComponent as ChevronRightIconSvg } from '../../assets/images/audit-sign/chevron-right.svg';
import { StyledHead, StyledInputPagination, StyledPaginationContainer, StyledTable } from './styles';

export interface IDataTypeGenericTable extends WithTranslation {
  headerData: (string | ReactNode)[];
  tableHeader?: ReactNode;
  tableData?: {
    [key: string]: string | ReactNode;
  }[];
  totalPages?: number;
  currentPage?: number;
  isTableLoading?: boolean;
  setIsTableLoading?: Dispatch<SetStateAction<boolean>>;
  paginationValue?: (page: number) => void;
}

const GenericTable = ({
  headerData,
  tableHeader,
  tableData,
  totalPages,
  currentPage,
  isTableLoading,
  setIsTableLoading,
  paginationValue,
  t,
}: IDataTypeGenericTable) => {
  const [timeoutPagination, setTimeoutPagination] = useState<NodeJS.Timeout | null>(null);
  const [page, setPage] = useState<number>(currentPage ?? 0);

  useEffect(() => {
    if (totalPages != null) {
      if (setIsTableLoading != null && page > 1) {
        setIsTableLoading(true);
      }

      if (page === 0 && totalPages > 0) {
        setPage(1);
        handlePagination(1);
        return;
      }

      if (page > totalPages) {
        setPage(totalPages);
      }

      if (page > 0) {
        handlePagination(page - 1);
      }
    }
  }, [page, totalPages]);

  useEffect(() => {
    return () => {
      if (timeoutPagination != null) {
        clearTimeout(timeoutPagination);
      }
    };
  }, []);

  const handlePagination = (page: number) => {
    if (timeoutPagination != null) {
      clearTimeout(timeoutPagination);
    }

    if (paginationValue != null) {
      setTimeoutPagination(
        setTimeout(() => {
          paginationValue(page);
        }, 500)
      );
    }
  };

  return (
    <div>
      <StyledTable loading={isTableLoading ?? false}>
        <StyledHead>
          {tableHeader != null && <tr>{tableHeader}</tr>}

          <tr>
            {headerData.map((headerData, index) => (
              <th key={`header-${index}`}>{headerData}</th>
            ))}
          </tr>
        </StyledHead>
        <tbody>
          {tableData?.map((tableData, rowIndex) => (
            <tr key={`data-row-${rowIndex}`}>
              {Object.values(tableData).map((data, dataIndex) => (
                <td key={`data-${dataIndex}`}>{data}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
      {currentPage != null && (
        <StyledPaginationContainer>
          {t('genericTable.pagination.page')}
          <StyledInputPagination
            type="number"
            value={page}
            onChange={({ target }) => setPage(Number(target.value))}
            disabled={totalPages == null || totalPages < 2}
          />
          {t('genericTable.pagination.numberPages', { totalPages })}
          <ChevronLeftIconSvg
            style={{ margin: '0 -1px 0 8px', cursor: 'pointer' }}
            onClick={() => {
              if (totalPages != null && page > 1) {
                setPage(page - 1);
              }
            }}
          />
          <ChevronRightIconSvg
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (totalPages != null && page < totalPages) {
                setPage(page + 1);
              }
            }}
          />
        </StyledPaginationContainer>
      )}
    </div>
  );
};

export default withTranslation()(GenericTable);
