import { DropdonwHeaderIcons } from 'assets/images/header';
import UseCustomStyleContext from 'context/custom-style';
import { isEmpty } from 'lodash';
import { RoutePath } from 'model/enums/routes-path';
import { useContext } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'redux/storeConfig';
import AuthUtils from 'shared/util/auth-utils';
import UserDropdown, { ListType } from './dropdown';
import { StyledContainer, StyledFooter, StyledHeader, StyledImage, StyledInnerContainer, StyledLink, StyledNavigation } from './styles';

interface IProps extends WithTranslation {
  children: JSX.Element;
}

const BoxHeaderAndFooter = ({ children, t }: IProps) => {
  const { contract } = useSelector((state: RootState) => state);
  const { pathname } = useLocation();
  const account = AuthUtils.getAccount();

  const username = account?.firstName;
  const dropdonwList: ListType[] = [{ icon: <DropdonwHeaderIcons.logOut />, text: t('header.logout'), link: RoutePath.LOGOUT }];
  const showNavigation = pathname === '/assinaturas-pendentes' || pathname === '/historico-assinaturas';
  const { logo } = useContext(UseCustomStyleContext);

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledInnerContainer lineUpCorners={!isEmpty(username)}>
          <StyledImage src={logo} alt="logo" />
          {!isEmpty(username) && (
            <StyledNavigation>
              {showNavigation && (
                <>
                  <StyledLink href="/assinaturas-pendentes" isEnabled={pathname === '/assinaturas-pendentes'}>
                    {t('header.pendingSubscriptions')}
                  </StyledLink>
                  <StyledLink href="/historico-assinaturas" isEnabled={pathname === '/historico-assinaturas'}>
                    {t('header.subscriptionHistory')}
                  </StyledLink>
                </>
              )}

              {AuthUtils.isAuthenticated() && <UserDropdown list={dropdonwList} />}
            </StyledNavigation>
          )}
        </StyledInnerContainer>
      </StyledHeader>
      {children}
      <StyledFooter>{t('footer.text')}</StyledFooter>
    </StyledContainer>
  );
};

export default withTranslation()(BoxHeaderAndFooter);
