import { createGlobalStyle } from 'styled-components';
import openSansBold from '../../src/assets/fonts/OpenSans-Bold.woff';
import openSansExtraBold from '../../src/assets/fonts/OpenSans-ExtraBold.woff';
import openSansMedium from '../../src/assets/fonts/OpenSans-Medium.woff';
import openSansRegular from '../../src/assets/fonts/OpenSans-Regular.woff';
import openSansSemiBold from '../../src/assets/fonts/OpenSans-SemiBold.woff';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: ${({ theme }) => theme.font.regular};
    src: url(${openSansRegular}) format('woff');
  }

  @font-face {
    font-family: ${({ theme }) => theme.font.medium};
    src: url(${openSansMedium}) format('woff');
  }

  @font-face {
    font-family: ${({ theme }) => theme.font.semiBold};
    src: url(${openSansSemiBold}) format('woff');
  }

  @font-face {
    font-family: ${({ theme }) => theme.font.bold};
    src: url(${openSansBold}) format('woff');
  }

  @font-face {
    font-family: ${({ theme }) => theme.font.extraBold};
    src: url(${openSansExtraBold}) format('woff');
  }

  body {
    font-family: ${({ theme }) => theme.font.regular}, sans-serif;
  }
`;

export default GlobalStyle;
