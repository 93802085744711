import LoadingDocument from 'components/loading-cocument/LoadingDocument';
import useWindowSize from 'hooks/useWindowSize';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { ReactComponent as ClearIconSvg } from '../../assets/images/audit-sign/clear.svg';
import { StyledContractContainer, StyledFooter, StyledIconContainer } from './styles';
import BlueGenericButton from 'components/blue-generic-button/BlueGenericButton';
import { isEmpty } from 'lodash';

interface IProps {
  isOpen: boolean;
  contentType?: string;
  documentBase64?: string;
  textButton?: string;
  iconButton?: JSX.Element;
  buttonLoading?: boolean;
  onCloseModal: () => void;
  handleClickButton?: () => void;
}

const ModalViewingContract = ({
  isOpen,
  contentType,
  documentBase64,
  textButton,
  iconButton,
  buttonLoading,
  onCloseModal,
  handleClickButton,
}: IProps) => {
  const [width] = useWindowSize();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [scale, setScale] = useState<number>(0.6);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  useEffect(() => {
    if (width > 576 && width < 991.98) {
      setScale(1);
    }

    if (width > 992) {
      setScale(1.6);
    }
  }, [width]);

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 23px',
    },
    content: {
      width: width > 991.98 ? '100%' : '90%',
      maxWidth: '854px',
      height: '100%',
      maxHeight: '576px',
      inset: 'initial',
      padding: 0,
      border: 'none',
      borderRadius: '8px',
      boxShadow: '0px 6px 10px 4px rgba(0, 0, 0, 0.05), 0px 2px 3px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    },
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={() => onCloseModal()} style={customStyles} ariaHideApp={false}>
      <StyledIconContainer onClick={() => onCloseModal()}>
        <ClearIconSvg />
      </StyledIconContainer>

      <StyledContractContainer>
        <Document file={`data:${contentType};base64,${documentBase64}`} loading={<LoadingDocument />} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.apply(null, new Array(totalPages))
            .map((x, i) => i + 1)
            .map(page => (
              <Page key={`page-${page}`} pageNumber={page} scale={scale} loading="" />
            ))}
        </Document>
      </StyledContractContainer>

      {handleClickButton != null && !isEmpty(textButton) && (
        <StyledFooter>
          <BlueGenericButton onClick={() => handleClickButton()} isLoading={buttonLoading} disabled={buttonLoading}>
            {iconButton != null && iconButton}
            {textButton}
          </BlueGenericButton>
        </StyledFooter>
      )}
    </Modal>
  );
};

export default ModalViewingContract;
