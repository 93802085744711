import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
`;

export const StyledTableCotainer = styled.div`
  margin: 24px 0;
`;

export const StyledTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledContractContainer = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const StyledContractName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray};
`;

export const StyledContractData = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.gray50};
`;

export const StyledStatusContainer = styled.div`
  min-width: 225px;
  text-align: center;
  padding: 0 60px;
  border-left: ${({ theme }) => `1px solid ${theme.color.gray3}`};
`;

export const StyledPdfDownloadButton = styled.button`
  border: none;
  background: none;
  padding: 0;

  &:focus-visible {
    outline: none;
  }
`;

export const StyledIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 60px;

  border-left: ${({ theme }) => `1px solid ${theme.color.gray3}`};

  circle {
    fill: ${({ theme }) => theme.color.gray3};
  }

  rect {
    fill: ${({ theme }) => theme.color.gray3};
  }
`;

export const StyleInnerIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 12px;
`;

export const StyledSignatureStatus = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;
