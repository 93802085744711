import React from 'react';
import { UseCardVerificationContextProvider } from './card-verification';
import { UseCustomStyleContextProvider } from './custom-style';

interface GlobalContextProps {
  children: JSX.Element;
}

const GlobalContext: React.FC<GlobalContextProps> = ({ children }) => {
  return (
    <UseCustomStyleContextProvider>
      <UseCardVerificationContextProvider>{children}</UseCardVerificationContextProvider>
    </UseCustomStyleContextProvider>
  );
};

export default GlobalContext;
