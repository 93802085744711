import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import AuthUtils from 'shared/util/auth-utils';
import StringUtils from 'shared/util/string-utils';
import LoggedUserIcon from '../../../assets/images/audit-sign/logged-user-icon.svg';
import { DropdownContainer, DropdownItem, DropdownText, LoggedUserBox, ProfileImage, Separator, StyledText } from './style';

export interface ListType {
  icon?: ReactElement;
  text?: string;
  link?: string;
  modalType?: string;
}

export interface UserDropdownProps {
  list?: ListType[];
}

const UserDropdown: React.FC<UserDropdownProps> = ({ list }) => {
  const navigate = useNavigate();

  const [closeOnDocumentClick, setCloseOnDocumentClick] = useState(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const account = AuthUtils.getAccount();

  return (
    <>
      <Popup
        trigger={
          <LoggedUserBox>
            <ProfileImage src={account?.imageUrl ? account.imageUrl : LoggedUserIcon} />
            <StyledText>{StringUtils.getFirstName(account?.firstName ?? '')}</StyledText>
          </LoggedUserBox>
        }
        position="bottom center"
        closeOnDocumentClick={closeOnDocumentClick}
        on="click"
        arrow={false}
      >
        <DropdownContainer>
          {list?.map(
            (item, idx, arr) =>
              item?.text != null && (
                <div key={idx}>
                  <DropdownItem onClick={() => navigate(item?.link ?? '')}>
                    {item?.icon ?? ''}
                    <DropdownText>{item.text}</DropdownText>
                  </DropdownItem>
                  <Separator isLast={idx === arr.length - 1} />
                </div>
              )
          )}
        </DropdownContainer>
      </Popup>
    </>
  );
};

export default UserDropdown;
