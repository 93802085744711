import ErrorMessage from 'components/error-message/ErrorMessage';
import { ButtonHTMLAttributes } from 'react';
import { BeatLoader } from 'react-spinners';
import { StyledButton, StyledContainer } from './styles';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  isLoading?: boolean;
  errorMessage?: string;
  invertButtonColor?: boolean;
}

const BlueGenericButton = ({ disabled, isLoading, errorMessage, invertButtonColor, ...rest }: IProps) => {
  return (
    <StyledContainer>
      <StyledButton {...rest} disabled={disabled} invertButtonColor={invertButtonColor} type="submit">
        {isLoading ? <BeatLoader color="#ffffff" size={11} cssOverride={{ display: 'flex', justifyContent: 'center' }} /> : rest.children}
      </StyledButton>
      <ErrorMessage text={errorMessage ?? ''} />
    </StyledContainer>
  );
};

export default BlueGenericButton;
