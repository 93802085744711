import { ReactComponent as CheckboxNotSelectedSvg } from 'assets/images/audit-sign/checkbox-not-selected.svg';
import { ReactComponent as CheckboxSelectedSvg } from 'assets/images/audit-sign/checkbox-selected.svg';
import BlueGenericButton from 'components/blue-generic-button/BlueGenericButton';
import CardShowContract from 'components/card-show-contract/CardShowContract';
import PageTitleSection from 'components/page-title-section/PageTitleSection';
import SubscriptionInformationCard from 'components/subscription-information-card/SubscriptionInformationCard';
import useHelperNavigation, { routeNextStepSignature } from 'hooks/useHelperNavigation';
import { ISubscriptionData } from 'model/contract-details';
import SignatureMethods from 'model/enums/signature-methods';
import { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addSignatureData } from 'redux/slice/data-signing-contract';
import { RootState } from 'redux/storeConfig';
import {
  StyledButtonContainer,
  StyledCardShowContractContainer,
  StyledCheckboxContainer,
  StyledCheckboxText,
  StyledContainer,
  StyledInnerContainer,
  StyledSubTitle,
  StyledText,
} from './styles';

const AcceptanceTerms = ({ t }: WithTranslation) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contract, signature } = useSelector((state: RootState) => state);
  const { sendSignature } = useHelperNavigation();
  const [accepted, setAccepted] = useState<boolean>(false);

  const handleConfirm = () => {
    const nextStep = routeNextStepSignature(SignatureMethods.ACCEPTED_TERMS, contract.contracDetails?.signatureMethods);

    dispatch(addSignatureData({ acceptanceTerms: accepted }));

    if (nextStep != null) {
      navigate(nextStep);
    } else {
      const isEnvelopeSignature = contract.contracDetails?.envelope != null;
      const updatedSubscriptionData: ISubscriptionData = { ...signature.subscriptionData, acceptanceTerms: accepted };

      sendSignature(updatedSubscriptionData, isEnvelopeSignature);
    }
  };

  return (
    <StyledContainer>
      <PageTitleSection
        signatureMethods={contract.contracDetails?.signatureMethods}
        currentSignatureMethod={SignatureMethods.ACCEPTED_TERMS}
        i18nKeyTitle="acceptanceTerms.title"
        i18nKeySubtitle="acceptanceTerms.subTitle"
        textAlign="left"
      />

      <StyledInnerContainer>
        <div>
          <StyledSubTitle style={{ margin: 0 }}>{t('acceptanceTerms.creditTitle')}</StyledSubTitle>
          <StyledText>{t('acceptanceTerms.creditText')}</StyledText>

          <StyledSubTitle>{t('acceptanceTerms.serviceTitle')}</StyledSubTitle>
          <StyledText>{t('acceptanceTerms.serviceText')}</StyledText>

          <StyledSubTitle>{t('acceptanceTerms.confirmTitle')}</StyledSubTitle>
          <StyledText>{t('acceptanceTerms.confirmText')}</StyledText>
        </div>

        <StyledCheckboxContainer onClick={() => setAccepted(!accepted)}>
          <div style={{ width: 24 }}>{accepted ? <CheckboxSelectedSvg /> : <CheckboxNotSelectedSvg />}</div>
          <StyledCheckboxText>{t('acceptanceTerms.readCheckbox')}</StyledCheckboxText>
        </StyledCheckboxContainer>

        <StyledButtonContainer>
          <BlueGenericButton disabled={!accepted} onClick={handleConfirm}>
            {t('global.button.confirm')}
          </BlueGenericButton>
        </StyledButtonContainer>

        <StyledCardShowContractContainer>
          {contract.contracDetails?.batchSignatureData != null ? <SubscriptionInformationCard /> : <CardShowContract />}
        </StyledCardShowContractContainer>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default withTranslation()(AcceptanceTerms);
