import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { SERVER_API_URL } from 'config/constants';
import { resetAuthentication } from 'redux/slice/authentication';
import AuthUtils from 'shared/util/auth-utils';

export const createNewApi = (baseUrl: string | undefined = SERVER_API_URL, apiName: string) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async headers => {
      const token: string | null = await AuthUtils.getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });

  const baseQueryWithStatusTreatment: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
      await AuthUtils.removeToken();
      api.dispatch(resetAuthentication());
    }
    return result;
  };

  return createApi({
    baseQuery: baseQueryWithStatusTreatment,
    endpoints: () => ({}),
    reducerPath: apiName,
  });
};

export const rtkApi = createNewApi(SERVER_API_URL, 'api');
