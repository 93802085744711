import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 400px;
  min-width: 256px;
  padding: 32px 32px 24px 32px;

  background-color: ${({ theme }) => theme.color.colorPrimary};
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.4;
  text-align: left;

  @media (min-width: 768px) {
    max-height: 272px;
  }
`;

export const StyledTitle = styled.div`
  font-family: ${({ theme }) => theme.font.semiBold};
  margin-bottom: 1px;
`;
