import { WithTranslation, withTranslation } from 'react-i18next';
import {
  StyleInnerIconsContainer,
  StyledContainer,
  StyledContractContainer,
  StyledContractData,
  StyledContractName,
  StyledIconsContainer,
  StyledPdfDownloadButton,
  StyledSignatureStatus,
  StyledStatusContainer,
  StyledTableCotainer,
  StyledTableHeader,
} from './styles';
import PageTitleSection from 'components/page-title-section/PageTitleSection';
import GenericTable from 'components/generic-table/GenericTable';
import ITypeGenericTable from 'model/type-generic-table';
import Status from 'components/status/Status';
import { ReactComponent as ContractIconSvg } from '../../assets/images/audit-sign/blue-contract-icon.svg';
import { ReactComponent as DownloadIconSvg } from '../../assets/images/audit-sign/download-icon.svg';
import { ReactComponent as PrintIconSvg } from '../../assets/images/audit-sign/print-icon.svg';
import { ReactComponent as CheckSvg } from '../../assets/images/audit-sign/green-check.svg';
import { ReactComponent as EllipseSvg } from '../../assets/images/audit-sign/gray-ellipse.svg';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useLocation, useNavigate } from 'react-router-dom';
import IDocument from 'model/document';
import axios from 'axios';
import { SignatureStatus, SignatureType } from 'model/enums/signature';
import ModalViewingContract from 'components/modal-viewing-contract/ModalViewingContract';
import { useEffect, useState } from 'react';
import { TypeBatchSubscription } from 'model/type-batch-subscription';
import companyService from 'services/company-service';
import SignatureMethods from 'model/enums/signature-methods';
import personService from 'services/person-service';
import AuthUtils from 'shared/util/auth-utils';
import IContractDetails from 'model/contract-details';
import { useDispatch } from 'react-redux';
import { addContracDetails } from 'redux/slice/contract-details';
import LocalStorageKeys from 'model/enums/local-storage-keys';

const ContractSignatures = ({ t }: WithTranslation) => {
  const account = AuthUtils.getAccount();
  const documentData = useLocation().state as IDocument;
  const uuid = localStorage.getItem(LocalStorageKeys.SIGNATURE_UUID);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [originalDocumentBase64, setOriginalDocumentBase64] = useState<string>();
  const [signedDocumentBase64, setSignedDocumentBase64] = useState<string>();
  const [isShowModalViewContract, setIsShowModalViewContract] = useState<boolean>(false);
  const [isShowSubscribeButton, setIsShowSubscribeButton] = useState<boolean>(false);
  const [isSubscribeButtonLoading, setIsSubscribeButtonLoading] = useState<boolean>(false);

  useEffect(() => {
    const havePendingSubscriptions = documentData.personSignatures.map(subscriber => subscriber.status).includes(SignatureStatus.PENDING);
    setIsShowSubscribeButton(havePendingSubscriptions);

    handleConvertingDocumentToBase64();
  }, []);

  const formatContractCreationDate = (date: string) => {
    const utcDate = new Date(date);
    const offset = utcDate.getTimezoneOffset();
    utcDate.setMinutes(utcDate.getMinutes() + offset);
    const formattedDate = format(utcDate, "d MMM yyyy 'às' HH:mm", { locale: ptBR });

    return t('contractSignatures.table.addedIn', { date: formattedDate });
  };

  const handleConvertingDocumentToBase64 = async () => {
    if (documentData?.originalDocument?.presignedUrl != null) {
      await axios
        .get<string>(documentData.originalDocument.presignedUrl, {
          responseType: 'arraybuffer',
        })
        .then(response => setOriginalDocumentBase64(Buffer.from(response.data, 'binary').toString('base64')));
    }

    if (documentData?.signedDocument?.presignedUrl != null) {
      await axios
        .get<string>(documentData.signedDocument.presignedUrl, {
          responseType: 'arraybuffer',
        })
        .then(response => setSignedDocumentBase64(Buffer.from(response.data, 'binary').toString('base64')));
    }
  };

  const handleSendBatchSignatureTokenToEmail = () => {
    setIsSubscribeButtonLoading(true);
    const subscriptionData: TypeBatchSubscription = {};

    if (uuid != null) {
      subscriptionData.documentsIds = [documentData.id];
      subscriptionData.companyUuid = uuid;

      companyService.sendBatchSignatureTokenToEmail(subscriptionData).then(response => {
        response.unshift(SignatureMethods.DATA_CONFIRMATION);

        if (account?.login != null) {
          personService.getPersonData(account?.login).then(personData => {
            const contractDetails: IContractDetails = {
              signatureMethods: response,
              name: account?.firstName,
              email: account?.email,
              birth: personData.birth ?? '',
              person: {
                id: personData.id,
                cpf: personData.cpf,
              },
              batchSignatureData: subscriptionData,
            };
            dispatch(addContracDetails(contractDetails));
            setIsSubscribeButtonLoading(false);
            navigate('/etapas-assinatura');
          });
        }
      });
    }
  };

  const handleDownloadDocument = async (contentType: string, documentBase64: string, documentName?: string) => {
    if (originalDocumentBase64 != null) {
      const linkSource = `data:${contentType};base64,${documentBase64}`;
      const link = document.createElement('a');

      link.href = linkSource;
      link.download = documentName ?? documentData.name;
      link.click();
    }
  };

  const tableHeader = () => (
    <th colSpan={4} style={{ border: 'none' }}>
      <StyledTableHeader>
        <StyledContractContainer>
          <ContractIconSvg />
          <div>
            <StyledContractName>{documentData.name}</StyledContractName>
            <StyledContractData>{formatContractCreationDate(documentData.originalDocumentDate)}</StyledContractData>
          </div>
        </StyledContractContainer>

        <StyledStatusContainer>
          <StyledContractData>{t('contractSignatures.table.contractStatus')}</StyledContractData>
          <Status status={documentData.status} />
        </StyledStatusContainer>

        {documentData?.originalDocument?.presignedUrl != null && (
          <StyledIconsContainer>
            <StyledContractData>{t('contractSignatures.table.originalDocument')}</StyledContractData>
            <StyleInnerIconsContainer>
              <StyledPdfDownloadButton
                onClick={() => {
                  if (originalDocumentBase64 != null) {
                    handleDownloadDocument(documentData.originalDocument.contentType, originalDocumentBase64);
                  }
                }}
              >
                <DownloadIconSvg />
              </StyledPdfDownloadButton>
              <a download={`${documentData.name}`} href={documentData.originalDocument.presignedUrl} target="_blank">
                <PrintIconSvg />
              </a>
            </StyleInnerIconsContainer>
          </StyledIconsContainer>
        )}

        {documentData?.signedDocument?.presignedUrl != null && (
          <StyledIconsContainer style={{ paddingRight: 0 }}>
            <StyledContractData>{t('contractSignatures.table.signedDocument')}</StyledContractData>
            <StyleInnerIconsContainer>
              <StyledPdfDownloadButton
                onClick={() => {
                  if (signedDocumentBase64 != null) {
                    handleDownloadDocument(
                      documentData.signedDocument.contentType,
                      signedDocumentBase64,
                      documentData?.signedDocument?.fileName
                    );
                  }
                }}
              >
                <DownloadIconSvg />
              </StyledPdfDownloadButton>
              <a download={`${documentData?.signedDocument?.fileName}`} href={documentData.signedDocument.presignedUrl} target="_blank">
                <PrintIconSvg />
              </a>
            </StyleInnerIconsContainer>
          </StyledIconsContainer>
        )}
      </StyledTableHeader>
    </th>
  );

  const subscriptionStatus = (signatureStatus: SignatureStatus, signatureType: SignatureType) => (
    <StyledSignatureStatus>
      {signatureStatus === SignatureStatus.SIGNED ? <CheckSvg /> : <EllipseSvg />}
      <span>{t(`contractSignatures.table.${signatureType.toLowerCase()}`)}</span>
    </StyledSignatureStatus>
  );

  const dataGenericTable: ITypeGenericTable = {
    headerData: [t('contractSignatures.table.signatory'), t('contractSignatures.table.email'), t('contractSignatures.table.signedAs')],
    tableData: documentData.personSignatures.map(subscriber => ({
      name: subscriber.name,
      email: subscriber.email,
      signedAs: subscriptionStatus(subscriber.status, subscriber.signatureType),
    })),
  };

  return (
    <StyledContainer>
      <PageTitleSection
        i18nKeyTitle="contractSignatures.title"
        i18nKeyMainButtonText={isShowSubscribeButton ? 'global.button.signContract' : undefined}
        mainButtonLoading={isSubscribeButtonLoading}
        handleClickMainButton={() => handleSendBatchSignatureTokenToEmail()}
        i18nKeyButtonText={'global.button.readContract'}
        handleClickButton={() => {
          if (originalDocumentBase64 != null) {
            setIsShowModalViewContract(true);
          }
        }}
        disableMainButton={isSubscribeButtonLoading}
        showBackButton
      />

      <StyledTableCotainer>
        <GenericTable headerData={dataGenericTable.headerData} tableData={dataGenericTable.tableData} tableHeader={tableHeader()} />
      </StyledTableCotainer>

      <ModalViewingContract
        isOpen={isShowModalViewContract}
        onCloseModal={() => setIsShowModalViewContract(false)}
        documentBase64={originalDocumentBase64}
        contentType={documentData.originalDocument.contentType}
        buttonLoading={isSubscribeButtonLoading}
        handleClickButton={() => handleSendBatchSignatureTokenToEmail()}
        textButton={isShowSubscribeButton ? t('global.button.toSign') : ''}
      />
    </StyledContainer>
  );
};

export default withTranslation()(ContractSignatures);
