import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;

  @media (max-width: 736.98px) {
    .title {
      text-align: center !important;
    }
  }
`;

export const StyledCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 12px;

  @media (max-width: 736.98px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
