import personApi from 'api/person-api';
import { AxiosError, AxiosResponse } from 'axios';
import { IPerson } from 'model/contract-details';

export const personService = () => {
  const getPersonData = async (cpf: string) => {
    try {
      const result: AxiosResponse<IPerson> = await personApi.getPersonData(cpf);
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getPersonData,
  };
};

export default personService();
