import LoadingScreen from 'components/loading-screen/loading-screen';
import { GenericFormData } from 'model/generic-form-data';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoMdArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import Input from './input';
import {
  ContentContainer,
  StyledBackButton,
  StyledButton,
  StyledForm,
  StyledLink,
  StyledPage,
  StyledSubtitle,
  StyledTitle,
} from './styles';

export interface InputProps {
  label: string;
  name: string;
  type: string;
  placeholder: string;
  formData?: GenericFormData;
  setFormData?: (value) => void;
  mask?: (value: string) => string;
  maxLength?: number;
}

interface GenericSignInProps {
  title: string;
  subtitles: string[];
  inputList: InputProps[];
  buttonText?: string;
  handleSubmit?: _.DebouncedFunc<() => Promise<void>>;
  methods?: UseFormReturn<FieldValues | any>;
  resendEmail?: () => void;
  route?: string;
  formData?: GenericFormData;
  setFormData?: (value) => void;
  onClick?: () => void;
  hasBackButton?: boolean;
  missingPassword?: boolean;
  isLoading?: boolean;
}

const GenericSignIn: React.FC<GenericSignInProps> = ({
  title,
  subtitles,
  inputList,
  buttonText,
  methods,
  handleSubmit,
  formData,
  setFormData,
  onClick,
  resendEmail,
  hasBackButton,
  missingPassword,
  route,
  isLoading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledPage>
      <ContentContainer>
        {!hasBackButton && (
          <StyledBackButton onClick={() => window.history.back()}>
            <IoMdArrowBack />
            {t('global.button.backStep')}
          </StyledBackButton>
        )}
        <StyledTitle>{title}</StyledTitle>

        {subtitles.map((subtitle, idx) =>
          idx === 1 ? (
            <StyledSubtitle key={idx} onClick={resendEmail}>
              {subtitle} <StyledLink>{t('signFlow.resend')}</StyledLink>
            </StyledSubtitle>
          ) : (
            <StyledSubtitle key={idx}>{subtitle}</StyledSubtitle>
          )
        )}
        {methods ? (
          <FormProvider {...methods}>
            <StyledForm
              onSubmit={e => {
                e.preventDefault();
                handleSubmit?.();
              }}
            >
              {inputList.map((input, idx) => (
                <Input
                  key={idx}
                  label={input.label}
                  type={input.type}
                  name={input.name}
                  placeholder={input.placeholder}
                  mask={input?.mask}
                  maxLength={input?.maxLength}
                  formData={formData}
                  setFormData={setFormData}
                />
              ))}
              {missingPassword && route && (
                <StyledSubtitle onClick={() => navigate(route)}>
                  <StyledLink>{t('signFlow.signin.forgotPassword')}</StyledLink>
                </StyledSubtitle>
              )}
              {buttonText && (
                <StyledButton onClick={onClick}>
                  <LoadingScreen isLoading={isLoading} width="100%" height="100%">
                    {buttonText}
                  </LoadingScreen>
                </StyledButton>
              )}
            </StyledForm>
          </FormProvider>
        ) : (
          buttonText && (
            <StyledButton onClick={onClick}>
              <LoadingScreen isLoading={isLoading} width="100%" height="100%">
                {buttonText}
              </LoadingScreen>
            </StyledButton>
          )
        )}
      </ContentContainer>
    </StyledPage>
  );
};

export default GenericSignIn;
