import { AxiosResponse } from 'axios';
import { api } from './api';
import { IPerson } from 'model/contract-details';

const personApi = () => {
  const getPersonData = (cpf: string): Promise<AxiosResponse<IPerson>> => {
    return api.get<IPerson>(`/people/cpf/${cpf}`);
  };

  return {
    getPersonData,
  };
};

export default personApi();
