import styled from 'styled-components/macro';
interface customStyleType {
  margin?: string;
  width?: string;
}
interface HeaderProps {
  variant?: string;
  isAuth?: boolean;
  customStyle?: customStyleType;
}

export const DropdownContainer = styled.div`
  min-width: 140px;
  background: ${({ theme }) => theme.color.white};

  margin: 8px 0px 0px 0px;
  padding: 8px 0px;

  border: ${({ theme }) => `1px solid ${theme.color.lightGray1}`};
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
`;

export const DropdownItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  :hover {
    background: ${({ theme }) => theme.color.lightGray1};
    border-radius: 8px;
  }
`;

export const DropdownText = styled.div`
  margin: 0px 0px 0px 4px;
  color: ${({ theme }) => theme.color.gray19};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

interface SeparatorProps {
  isLast?: boolean;
}

export const Separator = styled.div<SeparatorProps>`
  width: calc(100% - 32px);
  background: ${({ theme }) => theme.color.lightGray2};
  display: ${({ isLast }) => (isLast ? 'none' : 'block')};
  height: 1px;
  padding: 0px 16px;
  margin: 0px auto;
`;

export const LoggedUserBox = styled.button<HeaderProps>`
  height: 48px;
  padding: 8px;

  border-radius: 8px;

  border: ${({ theme }) => `1px solid ${lighten(0.1, theme.color.colorPrimary)}`};
  color: #ffffff;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: ${({ theme }) => lighten(0.1, theme.color.colorPrimary)};
    font-weight: bold;
  }
`;

export const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  margin: 0px 8px 0px 0px;
`;

export const StyledText = styled.div`
  font-weight: 500;
  font-size: 16px;

  color: ${({ theme }) => theme.color.white};
`;

import { lighten } from 'polished';
import Popup from 'reactjs-popup';

interface Props {
  error?: boolean;
  isFocused?: boolean;
  activated?: boolean;
  width?: number;
  flexWidth?: boolean;
  large?: boolean;
}

export const StyledPopUp = styled(Popup)`
  &-overlay {
    background: ${({ theme }) => theme.color.shadow};
  }
  &-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledContainer = styled.div<Props>`
  width: ${({ large }) => (large ? '630px' : 'max-content')};
  max-height: 416px;

  padding: ${({ large }) => (large ? '40' : '24')}px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

export const StyledXIconContainer = styled.div`
  text-align: right;
`;

export const StyledTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin: 15px 0 24px 0;
`;

export const StyledMessage = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray19};
`;

export const StyledButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  column-gap: 16px;
  margin-top: 40px;
`;

export const IconContainer = styled.div`
  margin-top: 20px;
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
`;
