import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useCreatePasswordMutation } from 'api/endpoints/user';
import GenericSignIn from 'components/generic-signIn';
import useRequestErrorHandler from 'hooks/useRequestErrorHandler';
import _ from 'lodash';
import { ICreatePassword } from 'model/contract-details';
import { InputNames } from 'model/enums/input-names';
import { InputTypes } from 'model/enums/input-types';
import { RoutePath } from 'model/enums/routes-path';
import { GenericFormData } from 'model/generic-form-data';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

interface CreatePasswordProps {}

const CreatePassword: React.FC<CreatePasswordProps> = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<GenericFormData>();
  const params = useParams();
  const [createPassword, { isLoading }] = useCreatePasswordMutation();

  const { openAlert, errorMessage, makeRequestWithErrorHandler } = useRequestErrorHandler();

  const navigate = useNavigate();

  const subtitles = [t('signFlow.passwordForAccess')];
  const inputList = [
    {
      label: t('signFlow.password'),
      name: InputNames.PASSWORD,
      type: InputTypes.PASSWORD,
      placeholder: t('signFlow.enterThePassword'),
      formData: formData,
      setFormData: setFormData,
    },
    {
      label: t('signFlow.passwordConfirmation'),
      name: InputNames.CONFIRM_PASSWORD,
      type: InputTypes.PASSWORD,
      placeholder: t('signFlow.confirmYourPassword'),
      formData: formData,
      setFormData: setFormData,
    },
  ];

  const schema = yup.object().shape({
    [InputNames.PASSWORD]: yup.string().required(t('global.field.validations.required')),
    [InputNames.CONFIRM_PASSWORD]: yup
      .string()
      .oneOf([yup.ref(InputNames.PASSWORD), null], 'As senhas devem ser iguais.')
      .required(t('global.field.validations.required')),
  });
  const methods = useForm({ resolver: yupResolver(schema), mode: 'onSubmit' });

  const handleForm = async () => {
    const isValid = await methods.trigger();
    if (!isValid) {
      return;
    }
    makeRequestWithErrorHandler(async () => {
      const createPasswordData: ICreatePassword = {
        key: params.key,
        newPassword: formData?.password,
      };
      await createPassword(createPasswordData).unwrap();
      navigate(RoutePath.SIGNIN);
    });
  };

  const handleSubmit = _.debounce(handleForm, 500);

  return (
    <>
      <Snackbar open={openAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <GenericSignIn
        title={t('signFlow.createYourPassword')}
        subtitles={subtitles}
        inputList={inputList}
        buttonText={t('signFlow.create')}
        handleSubmit={handleSubmit}
        methods={methods}
        formData={formData}
        setFormData={setFormData}
        hasBackButton
        isLoading={isLoading}
      />
    </>
  );
};

export default CreatePassword;
