import styled from 'styled-components/macro';

interface IStyledProps {
  disabled?: boolean;
  contractBeenRead?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;

  min-height: 109px;
  padding: 16px;

  border-radius: 8px;
  box-shadow: 0px 2px 12px 0px #0000000f;
  cursor: pointer;
`;

export const InnerContainer = styled.div<IStyledProps>`
  display: flex;
  flex: 1;
  column-gap: 8px;
  align-items: center;
`;

export const ContractName = styled.div<IStyledProps>`
  font-weight: ${({ contractBeenRead }) => (contractBeenRead ? 400 : 600)};
  font-size: 14px;
  line-height: 19.07px;
  color: ${({ theme }) => theme.color.gray};
`;

export const SubscriptionType = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16.34px;
  color: ${({ theme }) => theme.color.gray};
`;
