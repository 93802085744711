import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { rtkApi } from 'api/rtkApi';
import contractDetailsReducer from './slice/contract-details';
import dataSigningContractReducer from './slice/data-signing-contract';
import locationDataReducer from './slice/location-data';

export const reducer = combineReducers({
  [rtkApi.reducerPath]: rtkApi.reducer,
  contract: contractDetailsReducer,
  location: locationDataReducer,
  signature: dataSigningContractReducer,
});
export const store = configureStore({
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(rtkApi.middleware),
  reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
