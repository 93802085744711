import styled from 'styled-components';

interface IStyledProps {
  loading: boolean;
}

export const StyledTable = styled.table<IStyledProps>`
  width: 100%;

  border-collapse: separate;
  border-spacing: 0;
  padding: 18px;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  opacity: ${({ loading }) => loading && 0.5};
  pointer-events: ${({ loading }) => loading && 'none'};

  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.05), 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  th {
    padding-bottom: 18px;
    border-bottom: ${({ theme }) => `1px solid ${theme.color.backgroundGray}`};
  }

  td {
    padding: 18px 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.color.backgroundGray}`};
    max-width: 212px;
  }

  td:nth-child(2) {
    padding-right: 16px;
  }

  td:nth-last-child(1) {
    text-align: end;
  }

  td:nth-child(3) {
    text-align: left;
  }

  tr:nth-last-child(1) {
    td {
      border: none;
      padding-bottom: 0;
    }
  }
`;

export const StyledHead = styled.thead`
  font-weight: 600;
  color: ${({ theme }) => theme.color.gray};
`;

export const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: end;

  margin-top: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #6f6f6f;
  text-align: right;

  path {
    stroke: ${({ theme }) => theme.color.colorPrimary};
  }

  g {
    path:nth-child(2) {
      fill: ${({ theme }) => theme.color.colorPrimary};
    }
  }
`;

export const StyledInputPagination = styled.input`
  width: 50px;
  height: 24px;
  margin: 0 8px;

  border: 1px solid #6f6f6f;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  &:focus-visible {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
`;
