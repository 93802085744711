import styled from 'styled-components/macro';

export const IconContainer = styled.div`
  text-align: right;
  margin: 16px;
  cursor: pointer;
`;

export const Container = styled.div`
  margin: 16px;
`;

export const CardContainer = styled.main`
  margin: 24px 0;
  row-gap: 8px;
`;

export const customStyles = (width: number) => ({
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 23px',
  },
  content: {
    width: width > 991.98 ? '100%' : '90%',
    maxWidth: '854px',
    maxHeight: '576px',
    inset: 'initial',
    padding: 0,
    border: 'none',
    borderRadius: '8px',
    boxShadow: '0px 6px 10px 4px rgba(0, 0, 0, 0.05), 0px 2px 3px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
});
