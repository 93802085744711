import SmallLoading from 'components/small-loading/SmallLoading';
import * as S from './styles';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import contractService from 'services/contract-service';
import { format, parse } from 'date-fns';
import SignatureMethods from 'model/enums/signature-methods';
import { isMobile } from 'react-device-detect';
import UseCustomStyleContext from 'context/custom-style';
import { useDispatch } from 'react-redux';
import { addContracDetails } from 'redux/slice/contract-details';
import IContractDetails, { ICompany, IDocument } from 'model/contract-details';
import { orderDocuments } from 'shared/util/utils';
import useUrlToken from 'hooks/useUrlToken';

const TakesContractAndRedirectViewContract = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subscriptionKey } = useParams();
  const { setColorPrimary, setColorSecundary, setLogo } = useContext(UseCustomStyleContext);
  const { getTokenFromUrl, setTokenToStorage } = useUrlToken();
  const token = getTokenFromUrl();

  useEffect(() => {
    if (subscriptionKey != null) {
      setTokenToStorage(token);

      contractService
        .getContract(subscriptionKey)
        .then(response => {
          response.subscriptionKey = subscriptionKey;
          response.signatureMethods?.unshift(SignatureMethods.DATA_CONFIRMATION);

          if (response.birth != null) {
            response.birth = format(parse(response.birth, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
          }

          if (response.envelope != null && response.envelope.documents.length > 0) {
            const documentsOrderedById = response.envelope.documents.sort(orderDocuments);
            response.envelope.documents = documentsOrderedById;
          }

          dispatch(addContracDetails(response));
          handleRedirectPreviewScreen(response);
        })
        .catch(() => navigate('/'));
    }
  }, []);

  const checkProceedMobile = (pathRedirect: string, signatureMethods?: SignatureMethods[]) => {
    const isRedirectToQrCodeScreen =
      (signatureMethods?.includes(SignatureMethods.SELFIE) || signatureMethods?.includes(SignatureMethods.SIGNATURE)) && !isMobile;

    if (isRedirectToQrCodeScreen) {
      if (token) {
        navigate(`/qr-code/${subscriptionKey}?token=${token}`);
      } else {
        navigate(`/qr-code/${subscriptionKey}`);
      }
    } else {
      navigate(pathRedirect);
    }
  };

  const handleCompanyStyle = (companyData: ICompany) => {
    if (companyData.colorPrimary != null && companyData.colorSecundary != null) {
      setColorPrimary(companyData.colorPrimary);
      setColorSecundary(companyData.colorSecundary);
      setLogo(`data:image/png;base64,${companyData.logo}`);
    }
  };

  const handleRedirectPreviewScreen = (contract: IContractDetails) => {
    if (contract?.document != null) {
      handleCompanyStyle(contract.document.company);
      checkProceedMobile('/documento', contract?.signatureMethods);
      return;
    }

    if (contract?.envelope != null) {
      handleCompanyStyle(contract?.envelope.documents[0].company);
      checkProceedMobile('/documentos', contract?.envelope.signatureMethods);
    }
  };

  return (
    <S.Container>
      <S.InnerContainer>
        <SmallLoading />
      </S.InnerContainer>
    </S.Container>
  );
};

export default TakesContractAndRedirectViewContract;
