import { createContext, useEffect, useState } from 'react';
import companyService from 'services/company-service';

interface IUseCardVerificationContextProvider {
  children: JSX.Element;
}

interface IPropsUseCardVerificationContext {
  hasOnlyOneCompany: boolean;
  companyLoading: boolean;
}

const DEFAULT_VALUE: IPropsUseCardVerificationContext = {
  hasOnlyOneCompany: false,
  companyLoading: false,
};

const UseCardVerificationContext = createContext<IPropsUseCardVerificationContext>(DEFAULT_VALUE);

const UseCardVerificationContextProvider: React.FC<IUseCardVerificationContextProvider> = ({ children }) => {
  const [hasOnlyOneCompany, setHasOnlyOneCompany] = useState<boolean>(DEFAULT_VALUE.hasOnlyOneCompany);
  const [companyLoading, setCompanyLoading] = useState<boolean>(false);

  useEffect(() => {
    setCompanyLoading(true);
    companyService.getAllCompanies().then(response => {
      setCompanyLoading(false);
      const hasOneCompany = response.length === 1;
      setHasOnlyOneCompany(hasOneCompany);
    });
  }, []);

  return (
    <UseCardVerificationContext.Provider value={{ hasOnlyOneCompany, companyLoading }}>{children}</UseCardVerificationContext.Provider>
  );
};

export { UseCardVerificationContextProvider };
export default UseCardVerificationContext;
