import { withTranslation, WithTranslation } from 'react-i18next';
import { ReactComponent as BatchSubscriptiondSvg } from '../../assets/images/audit-sign/batch-subscription.svg';
import { StyledContainer, StyledSvgContainer } from './styles';

const SubscriptionInformationCard = ({ t }: WithTranslation) => {
  return (
    <StyledContainer>
      {t('letsSign.youAreSigningDocumentsInBatch')}

      <StyledSvgContainer>
        <BatchSubscriptiondSvg style={{ width: '100%' }} />
      </StyledSvgContainer>
    </StyledContainer>
  );
};

export default withTranslation()(SubscriptionInformationCard);
