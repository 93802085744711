import styled from 'styled-components/macro';
interface IStyledProps {
  backgroundColor: string;
}

export const StyledContainer = styled.div<IStyledProps>`
  width: 100%;
  max-width: 360px;
  height: 200px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: 0.8;

  border-radius: 8px;

  cursor: pointer;

  @media (max-width: 575.98px) {
    margin: 0 12px;
  }
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const StyledTextContainer = styled.div`
  color: #ffffff;
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: 575.98px) {
    height: 42px;
    width: 42px;
    font-size: 20px;
  }
`;

export const StyledText = styled.div`
  max-width: 180px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  @media (max-width: 575.98px) {
    height: 42px;
    width: 42px;
    font-size: 11px;
  }
`;
