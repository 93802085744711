import styled from 'styled-components/macro';

interface StyledProps {
  hasError?: boolean;
}

export const StyledPage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0px 0px 0px;
`;

export const ContentContainer = styled.div`
  max-width: 400px;
  width: 100%;

  position: relative;
`;

export const StyledBackButton = styled.button`
  width: 74px;
  color: #78a9ff;

  background: none;
  border: none;
  padding: 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  position: absolute;
  top: -24px;
`;

export const StyledTitle = styled.div`
  width: 340px;
  font-weight: 700;
  font-size: 32px;
  color: #393939;

  margin: 40px 0px 0px;
`;
export const StyledSubtitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #393939;

  margin: 16px 0px 0px;
`;

export const StyledLink = styled.a`
  font-weight: 700;
  font-size: 14px;
  color: #0f62ce;

  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const StyledForm = styled.form``;

export const StyledLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 16px 0px 0px;
`;
export const StyledInputBox = styled.div<StyledProps>`
  width: 100%;
  height: 48px;

  background: ${({ hasError }) => (hasError ? '#FFF1F1' : '#f4f4f4')};

  border-radius: 8px;
  margin: 8px 0px 0px;

  padding: 0px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
`;
export const StyledInput = styled.input<StyledProps>`
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  max-width: 312px;
  background: none;
  border: none;
  outline: none;
  color: ${({ hasError }) => (hasError ? '#FA4D56' : '#393939')};
`;
export const StyledIconBox = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.gray50};
  font-size: 22px;
  cursor: pointer;
  z-index: 99;
`;

export const StyledError = styled.div`
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  color: #fa4d56;
  bottom: -20px;
  right: 0px;
`;

export const StyledButton = styled.button`
  width: 100%;
  height: 48px;
  margin: 40px 0px 0px;

  background: #0f62fe;
  border-radius: 8px;
  border: none;

  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
`;
