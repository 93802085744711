import { CSSProperties } from 'react';
import { StyledContainer } from './styles';

interface IProps {
  text: string;
  style?: CSSProperties;
}

const ErrorMessage = ({ text, style }: IProps) => {
  return <StyledContainer style={style}>{text}</StyledContainer>;
};

export default ErrorMessage;
