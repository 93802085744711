import styled from 'styled-components/macro';
export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 400px;
  min-width: 256px;
  padding: 24px;

  background-color: ${({ theme }) => theme.color.colorPrimary};
  border-radius: 8px;
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  font-weight: 400;
`;

export const StyledSvgContainer = styled.div`
  text-align: center;
  margin-top: 40px;

  svg > path:nth-child(39) {
    stroke: ${({ theme }) => theme.color.colorPrimary};
  }

  svg > path:nth-child(43) {
    fill: ${({ theme }) => theme.color.colorPrimary};
  }

  svg > path:nth-child(62) {
    fill: ${({ theme }) => theme.color.colorPrimary};
  }
`;
