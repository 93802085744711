import useWindowSize from 'hooks/useWindowSize';
import Modal from 'react-modal';
import { ReactComponent as ClearIconSvg } from '../../assets/images/audit-sign/clear.svg';
import * as S from './styles';
import { IDocument } from 'model/contract-details';
import ContractDataCard from 'components/contract-data-card';
import PageTitleSection from 'components/page-title-section/PageTitleSection';

interface IProps {
  isOpen: boolean;
  onCloseModal: () => void;
  contracts?: IDocument[];
}

const ModalViewingMultipleContracts = ({ contracts, isOpen, onCloseModal }: IProps) => {
  const [width] = useWindowSize();

  return (
    <Modal isOpen={isOpen} onRequestClose={() => onCloseModal()} style={S.customStyles(width)} ariaHideApp={false}>
      <S.IconContainer onClick={() => onCloseModal()}>
        <ClearIconSvg />
      </S.IconContainer>

      <S.Container>
        <PageTitleSection
          i18nKeyTitle="viewMultipleContractsToSign.title"
          i18nKeySubtitle="viewMultipleContractsToSign.subtitle"
          interpolatedValue={contracts?.length}
          style={{ justifyContent: 'center' }}
        />

        <S.CardContainer>
          {contracts?.map((contract, index) => (
            <ContractDataCard documentValue={contract} key={`contract-${index}`} />
          ))}
        </S.CardContainer>
      </S.Container>
    </Modal>
  );
};

export default ModalViewingMultipleContracts;
