import * as S from './styles';
import { ReactComponent as CheckSvg } from 'assets/images/audit-sign/green-check.svg';
import { ReactComponent as GrayEllipseSvg } from 'assets/images/audit-sign/gray-ellipse.svg';
import { ReactComponent as ChevronRightSvg } from 'assets/images/audit-sign/gray-chevron-right.svg';
import { useState } from 'react';
import { IDocument, IOriginalDocument } from 'model/contract-details';
import { WithTranslation, withTranslation } from 'react-i18next';
import ModalViewingContract from 'components/modal-viewing-contract/ModalViewingContract';
import { ReactComponent as DownloadIconSvg } from 'assets/images/audit-sign/download.svg';
import contractService from 'services/contract-service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/storeConfig';
import SignatureMethods from 'model/enums/signature-methods';
import { format, parse } from 'date-fns';
import { addContracDetails } from 'redux/slice/contract-details';
import { orderDocuments } from 'shared/util/utils';

interface IProps extends WithTranslation {
  documentValue: IDocument;
  showPreviewStatusIcon?: boolean;
}

const ContractDataCard = ({ documentValue, showPreviewStatusIcon, t }: IProps) => {
  const { contract } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [isShowModalViewContract, setIsShowModalViewContract] = useState<boolean>(false);

  const subscriptionKey = contract.contracDetails?.subscriptionKey;
  const uuid = documentValue?.uuid;

  const handleGetContrat = () => {
    if (subscriptionKey != null) {
      contractService.getContract(subscriptionKey).then(response => {
        response.subscriptionKey = subscriptionKey;
        response.signatureMethods?.unshift(SignatureMethods.DATA_CONFIRMATION);

        if (response.birth != null) {
          response.birth = format(parse(response.birth, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
        }

        if (response.envelope != null && response.envelope.documents.length > 0) {
          const documentsOrderedById = response.envelope.documents.sort(orderDocuments);
          response.envelope.documents = documentsOrderedById;
        }

        dispatch(addContracDetails(response));
      });
    }
  };

  const openContact = () => {
    if (uuid != null && subscriptionKey != null && !documentValue.seen) {
      contractService.openContract(uuid, subscriptionKey).then(() => handleGetContrat());
    }
  };

  const handleDownloadDocument = async (contract?: IOriginalDocument) => {
    const linkSource = `data:${contract?.contentType};base64,${contract?.documentBase64}`;
    const link = document.createElement('a');

    link.href = linkSource;
    link.download = contract?.fileName ?? '';
    link.click();
  };

  const handleOpenContract = () => {
    setIsShowModalViewContract(true);
    openContact();
  };

  return (
    <>
      <S.Container onClick={() => handleOpenContract()}>
        <S.InnerContainer>
          {showPreviewStatusIcon ? (
            documentValue?.seen ? (
              <CheckSvg style={{ minWidth: '8px' }} />
            ) : (
              <GrayEllipseSvg style={{ minWidth: '8px' }} />
            )
          ) : (
            <></>
          )}
          <div>
            <S.ContractName contractBeenRead={showPreviewStatusIcon && documentValue?.seen}>{documentValue.name}</S.ContractName>
            <S.SubscriptionType>
              {t(`viewMultipleContractsToSign.signatoryType.${contract.contracDetails?.signatureType?.toLowerCase()}`)}
            </S.SubscriptionType>
          </div>
        </S.InnerContainer>
        <ChevronRightSvg />
      </S.Container>

      <ModalViewingContract
        isOpen={isShowModalViewContract}
        onCloseModal={() => setIsShowModalViewContract(false)}
        documentBase64={documentValue.originalDocument?.documentBase64}
        contentType={documentValue.originalDocument?.contentType}
        handleClickButton={() => handleDownloadDocument(documentValue?.originalDocument)}
        textButton={t('global.button.downloadDocument')}
        iconButton={<DownloadIconSvg />}
      />
    </>
  );
};

export default withTranslation()(ContractDataCard);
