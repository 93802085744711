import SmallLoading from 'components/small-loading/SmallLoading';
import { withTranslation, WithTranslation } from 'react-i18next';
import StyledLoadingContainer from './styles';

const LoadingDocument = ({ t }: WithTranslation) => {
  return (
    <StyledLoadingContainer>
      <span>{t('global.field.loadingDocument')}</span>
      <SmallLoading />
    </StyledLoadingContainer>
  );
};

export default withTranslation()(LoadingDocument);
