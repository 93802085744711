import styled, { css } from 'styled-components/macro';
interface IStyledProps {
  disabled?: boolean;
  invertButtonColor?: boolean;
}

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 400px;

  svg {
    path {
      fill: ${({ theme }) => `1px solid ${theme.color.colorPrimary}`};
    }
  }
`;

export const StyledButton = styled.button<IStyledProps>`
  width: 100%;
  max-width: 400px;
  height: 48px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  border: ${({ theme }) => `1px solid ${theme.color.colorPrimary}`};
  background-color: ${({ theme }) => theme.color.colorPrimary};
  font-family: ${({ theme }) => theme.font.bold}, sans-serif;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  font-weight: 700;

  ${props =>
    props.invertButtonColor &&
    css`
      background-color: white;
      color: ${({ theme }) => theme.color.colorPrimary};
    `};

  ${props =>
    props.disabled &&
    css`
      color: ${({ theme }) => theme.color.gray50};
      background-color: #e0e0e0;
      border: none;
    `};
`;
