import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from 'model/user';
import AuthUtils from 'shared/util/auth-utils';

interface AuthenticationState {
  isAuthenticated: boolean;
  account?: User;
}

const initialState: AuthenticationState = {
  isAuthenticated: false,
  account: AuthUtils.getAccount(),
};

const authentication = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthentication: state => {
      state.isAuthenticated = true;
    },
    resetAuthentication: state => {
      state.isAuthenticated = false;
    },
    setAccount(state, action: PayloadAction<User>) {
      state.account = action.payload;
    },
    removeAccount(state) {
      state.account = undefined;
    },
  },
});

export const { setAuthentication, resetAuthentication, setAccount, removeAccount } = authentication.actions;

export default authentication.reducer;
